export const getTopics = (state) => state.topics;
export const getTopicsData = (state) => getTopics(state).data;
export const getTopicNavigationData = (state) => getTopics(state).navigation;
export const getIsTopicLoading = (state) => getTopics(state).isLoading;
export const getTopicId = (state) => state.topics.topicId;

export const getGrades = (state) => state.grades;
export const getGradesData = (state) => getGrades(state).data;
export const getIsGradeLoading = (state) => getGrades(state).isLoading;

export const getFilteredGrades = (state) => state.filteredGrades;
export const getFilteredGradesData = (state) => getFilteredGrades(state).data;
export const getIsFilteredGradeLoading = (state) =>
  getFilteredGrades(state).isLoading;

export const getStates = (state) => state.states;
export const getStatesData = (state) => getStates(state).data;
export const getIsStateLoading = (state) => getStates(state).isLoading;

export const getSchools = (state) => state.schools;
export const getSchoolsData = (state) => getSchools(state).data;
export const getIsSchoolLoading = (state) => getSchools(state).isLoading;

export const getRegions = (state) => state.regions;
export const getRegionsData = (state) => getRegions(state).data;
export const getIsRegionLoading = (state) => getRegions(state).isLoading;

export const getCountries = (state) => state.countries;
export const getCountriesData = (state) => getCountries(state).data;
export const getIsCountryLoading = (state) => getCountries(state).isLoading;

export const getCategories = (state) => state.categories;
export const getCategoriesData = (state) => getCategories(state).data;

export const getFilteredCategories = (state) => state.filteredCategories;
export const getFilteredCategoriesData = (state) => getFilteredCategories(state).data;
export const getFilteredCategoriesDataST = (state) => getFilteredCategories(state).dataST;

export const getSubjects = (state) => state.subjects;
export const getSubjectsData = (state) => getSubjects(state).data;
export const getSubjectsSettingsData = (state) => getSubjects(state).settings;
export const getIsSubjectLoading = (state) => getSubjects(state).isLoading;

export const getLessons = (state) => state.lessons;
export const getLessonsData = (state) => getLessons(state).data;
export const getLessonNavigationData = (state) => getLessons(state).navigation;
export const getIsLessonLoading = (state) => getLessons(state).isLoading;
export const getIsLessonTitle = (state) => state.lessons.lessonTitle;
export const getLessonData = (state) => state.lessons.lessonData;

export const getFilteredSubjects = (state) => state.filteredSubjects;
export const getFilteredSubjectsData = (state) =>
  getFilteredSubjects(state).data;
export const getIsFilteredSubjectLoading = (state) =>
  getFilteredSubjects(state).isLoading;

export const getUser = (state) => state.user;
export const getUserData = (state) => getUser(state).data;

export const getMenu = (state) => state.menu;
export const getMenuData = (state) => getMenu(state).data;
export const getPresentation = (state) => state.presentation;

export const getPresentationLoading = (state) => getPresentation(state).isLoading;

export const getPresentationData = (state) => getPresentation(state).data;
export const getPresentationFilteredTotal = (state) => getPresentation(state).filteredTotal;
export const getPresentationMode = (state) => state.presentationMode;
export const getPresentationModeData = (state) => getPresentationMode(state).data;
export const getPresentationModeLoading = (state) => getPresentationMode(state).isLoading;
export const getIsPresentationMode = (state) => getPresentationMode(state).isPresentationMode;
export const getIsPresentationShow = (state) => getPresentationMode(state).isPresentationShow;
export const getExternalPresentationData = (state) => getPresentationMode(state).externalPresentation;


export const getAbout = (state) => state.about;
export const getAboutData = (state) => getAbout(state).data;
export const getSlides = (state) => state.slides;
export const getSlidesData = (state) => getSlides(state).data;
export const getSlidesPage = (state) => getSlides(state).page;

export const getSlidesSelectedSlideData = (state) => getSlides(state).selectedSlide;
export const getSlidesFilteredLength = (state) => getSlides(state).filteredTotal;
export const getSlidesLoading = (state) => getSlides(state).isLoading;
export const getSlidesAllState = (state) => state.slidesManipulation;
export const getIsSlidePlay = (state) => getSlidesAllState(state).isPlaying;
export const getIsSeeAllMode = (state) => getSlidesAllState(state).isSeeAllMode;
export const getSprite = (state) => state.sprite;
export const getSpriteData = (state) => getSprite(state).data;
export const getSpriteURLData = (state) => getSprite(state).url + '/sprite@2x.png' + `?image=${getSprite(state).generatedUniqueId}`;
export const getSpriteBase64Data = (state) => getSprite(state).base64;

export const getSearch = (state) => state.search;
export const getSearchData = (state) => getSearch(state).data;

export const getMapStyle = (state) => state.mapStyle;
export const getMapStyleData = (state) => getMapStyle(state).data;

export const getPainterStart = (state) => state.painterStart;
export const getPainterStartData = (state) => getPainterStart(state).data;

export const getMapStyles = (state) =>state.mapStyles;
export const getMapStylesData = (state) => getMapStyles(state).data;
export const getMapStylesListData = (state) => getMapStyles(state).list;
export const getMapConfigData = (state) => getMapStyles(state).configurations;
export const getMapStylesExtraLayers = (state) => getMapStyles(state).mapStyleExtraLayers;
export const getSelectedMapStyle = (state) =>
  getMapStyles(state).data?.find?.((x) => x.active !== false);
export const getSelectedMapStyleDark = (state) =>
  getMapStyles(state).data?.find?.((x) => x.active !== false)?.active;

export const getMapRequirements = (state) => state.mapRequirements;
export const getMapRequirementsData = (state) => getMapRequirements(state).data;
export const getSelectedMapRequirements = (state) =>
  getMapRequirements(state).data.find((x) => x.active).value;

export const getHints = (state) => state.hints;
export const getHintsData = (state) => getHints(state).data;

export const getQuizPopup = (state) => state.quizPopup;
export const getQuizPopupData = (state) => getQuizPopup(state).data;

export const getMapZoom = (state) => state.mapZoom;
export const getMapZoomData = (state) => getMapZoom(state).data;

export const getMapZoomToActionData = (state) => getMapZoom(state).actionZoomTo;

export const getMapRequirement = (state) => state.mapRequirement;
export const getMapRequirementData = (state) => getMapRequirement(state).data;

export const getMapBounce = (state) => state.mapBounce;
export const getMapBounceData = (state) => getMapBounce(state).data;

export const getMenuExpend = (state) => state.menuExpend;
export const getMenuExpendData = (state) => getMenuExpend(state).data;

export const getTimeLineExpend = (state) => state.timeLineExpend;
export const getTimeLineExpendData = (state) => getTimeLineExpend(state).data;

export const getArticles = (state) => state.articles;
export const getSelectedArticles = (state) => state.articles;
export const getArticlesData = (state) => getArticles(state).data;
export const getSelectedArticleData = (state) => getSelectedArticles(state).selectedArticleData;
export const getIsArticlesLoading = (state) => getArticles(state).isLoading;

export const getArticlesAll = (state) => state.articlesAll;
export const getSelectedArticlesAll = (state) => state.articlesAll;
export const getArticlesDataAll = (state) => getArticlesAll(state).data;
export const getSelectedArticleDataAll = (state) =>
  getSelectedArticlesAll(state).selectedArticleDataAll;
export const getIsArticlesAllLoading = (state) =>
  getArticlesAll(state).isLoading;
export const getArticleIsLikedData = (state) =>
  getArticlesAll(state).aricleIsLiked;
export const getArticlePopUpFontSize = (state) =>
  getArticlesAll(state).articlePopUpFontSize;
export const getArticleReadMoreFontSize = (state) =>
  getArticlesAll(state).articleReadMoreFontSize;

export const getBookmarks = (state) => state.bookmarks;
export const getBookmarksLoading = (state) => getBookmarks(state).isLoading;
export const getBookmarksData = (state) => getBookmarks(state).data;

export const getLamp = (state) => state.lampArticles;
export const getLampModalState = (state) => state.lampArticles.lampModalState;

export const getLampModalStateIndex = (state) => state.lampArticles.lampModalStateIndex;
export const getLampModalData = (state) => state.lampArticles.lampModalData;
export const getLampData = (state) => getLamp(state).data;
export const getIsLampLoading = (state) => getLamp(state).isLoading;

export const getQuiz = (state) => state.quiz;
export const getQuizModalState = (state) => state.quiz.quizModalState;
export const getQuizModalData = (state) => state.quiz.quizModalData;
export const getQuizData = (state) => getQuiz(state).data;
export const getSelectedQuizData = (state) => getQuiz(state).selectedQuizData;
export const getIsQuizLoading = (state) => getQuiz(state).isLoading;

export const isUserLoggedIn = (state) => !!state.auth.data.token;

export const getTimeLineOptions = (state) => state.timeLineOptions;
export const getTimeLineOptionsData = (state) => getTimeLineOptions(state).data;
export const getMiddleTimeLineOptionsData = (state) => getTimeLineOptions(state).middleTimlinedata;
export const getMaxTimeLineOptionsData = (state) => getTimeLineOptions(state).maxTimlinedata;

export const getPrevLessonID = (state) => state.prevLessonId;
export const getPrevLessonIDData = (state) => getPrevLessonID(state).data;

export const getNextLessonID = (state) => state.nextLessonId;
export const getNextLessonIDData = (state) => getNextLessonID(state).data;

export const getGeoJSON = (state) => state.geoJSON;
export const getGeoJSONData = (state) => getGeoJSON(state).data;

export const getTimeGeoJSON = (state) => state.timeGeoJSON;
export const getTimeGeoJSONData = (state) => getTimeGeoJSON(state).data;

export const getTimeLineItems = (state) => state.timeLineItems;
export const getTimeLineItemsData = (state) => getTimeLineItems(state).data;

export const getMiddleTimeLineItemsData = (state) => getTimeLineItems(state).middleTimelineData;
export const getMiddleMaxTimeLineItemsData = (state) => getTimeLineItems(state).middleMaxTimelineData;
export const getTimeLineGroups = (state) => state.timeLineGroups;
export const getTimeLineGroupsData = (state) => getTimeLineGroups(state).groups;
export const getIsTimeLineLoading = (state) =>
  getTimeLineItems(state).isLoading;

export const getSelectedLesson = (state) => state.selectedLesson;
export const getSelectedLessonId = (state) => getSelectedLesson(state).data;

export const getSortedTooltipSelectItem = (state) =>
  state.lampArticles.sortedTooltipSelectItem;

export const getPainterGeoJsonData = (state) => state.painterGeoJson.data;
export const getPainterPrevData = (state) => state.painterGeoJson.prevData;
export const getPainterPrevLineNameData = (state) =>
  state.painterGeoJson.prevLineName;
export const getPainterLayerLineNameData = (state) =>
  state.painterGeoJson.layerLineName;

export const getResetState = (state) => state.reset.resetSate;

export const getMap = (state) => state.map;
export const getMapLoading = (state) => getMap(state).isLoading;

export const getMapSelectedCluster = (state) => getMap(state).selectedCluster;

export const getMapStateData = (state) => state.mapState.data;
export const getMapStateSingleData = (state) => state.mapState.singleData;
export const getOpenCategoryID = (state) => state.mapState.categoryId;
export const getMapMarkerInfo = (state) => state.mapState.markerInfo;
export const getOpenSubCategoryID = (state) => state.mapState.subCategoryId;
export const getMapRequiredScreenShotState = (state) =>
  state.mapState.mapRequiredScreenShot;
export const getCategoryArticleClickedId = (state) =>
  state.mapState.categoryArticleClickedId;
export const getLampDataFilteredScreenShot = (state) =>
  state.mapState.lampDataFilteredScreenShot;
export const getLampSortedTooltipState = (state) =>
  state.mapState.lampSortedTooltipState;
export const getTestFilteredCategories = (state) =>
  state.mapState.testFilteredCategories;
export const getLampSelectedId = (state) => state.mapState.lampSelectedId;
export const getMapStyledId = (state) => state.mapState.mapStylesId;
export const getMapStyledDarkId = (state) => state.mapState.mapStylesDarkId;
export const getDrawerToolboxClickedState = (state) =>
  state.painterStart.startDrawerClickState;
export const getPainterColor = (state) => state.painterStart.painterColor;
export const getPainterColorData = (state) =>
  state.painterStart.painterColorData;
export const getPainterSize = (state) => state.painterStart.painterSize;
export const getPainterSizeData = (state) => state.painterStart.painterSizeData;
export const getColorPickerShowState = (state) =>
  state.painterStart.colorPickerShowState;
export const getRulerClickedState = (state) =>
  state.painterStart.rulerClickedState;

export const getStickyNotesClickedState = (state) => state.painterStart.stickyNotesClickedState;
export const getStickyNotesMarkersState = (state) => state.mapState.stickyNotesMarkers;
export const getStickyNotesMarkersLoadingState = (state) => state.mapState.stickyNotesMarkersLoading;

export const getEraserClickedState = (state) =>
  state.painterStart.eraserClickedState;

export const getCommentToolboxState = (state) => state.painterStart.commentToolboxClickedState;
export const getCommentToolboxMarkersState = (state) => state.mapState.commentBoxMarkers;
export const getCommentToolboxMarkersLoadingState = (state) => state.mapState.commentBoxMarkersLoading;

export const getPolygonDrawToolboxState = (state) => state.painterStart.polygonDrawToolboxClickedState;
export const getPolygonDrawToolboxCurrentFillOpacity = (state) => state.painterStart.polygonDrawToolboxCurrentFillOpacity;
export const getPolygonDrawToolboxCurrentStrokeOpacity = (state) => state.painterStart.polygonDrawToolboxCurrentStrokeOpacity;
export const getPolygonDrawToolboxCurrentFillColor = (state) => state.painterStart.polygonDrawToolboxCurrentFillColor;
export const getPolygonDrawToolboxCurrentStrokeColor = (state) => state.painterStart.polygonDrawToolboxCurrentStrokeColor;
export const getPolygonDrawToolboxColors = (state) => state.painterStart.polygonDrawToolboxColors;
export const getPolygonDrawToolboxColorPickerState = (state) => state.painterStart.polygonDrawToolboxColorPickerState;
export const getPolygonDrawToolboxPolygonsState = (state) => state.mapState.polygonDrawToolboxPolygons;

export const getLineDrawToolboxState = (state) => state.painterStart.lineDrawToolboxClickedState;
export const getLineDrawToolboxWeight = (state) => state.painterStart.lineDrawToolboxWeight;
export const getLineDrawToolboxColors = (state) => state.painterStart.lineDrawToolboxColors;
export const getLineDrawToolboxCurrentColor = (state) => state.painterStart.lineDrawToolboxCurrentColor;
export const getLineDrawToolboxColorPickerState = (state) => state.painterStart.lineDrawToolboxColorPickerState;
export const getMapboxDrawToolboxGeoJSONState = (state) => state.mapState.mapboxDrawToolboxGeoJSON;

export const getRulerPopupShowST = (state) => state.mapState.rulerPopupHide;
export const getIsAllToolboxStatesLoaded = (state) => state.mapState.isAllStatesLoaded;
export const getSelectedToolUndoRedo = (state) => state.mapState.selectedToolUndoRedo;
export const getUndoRedoData = (state) => state.mapState.undoRedoData;
export const getBaseMapGroupIdST = (state) => state.mapState.baseMapGroupIdST
export const  getBaseMapIsLight = (state) => state.mapState.baseMapIsLight
export const getIsRulerStatesLoaded = (state) => state.mapState.isRulerStatesLoaded;
export const getMapStylesChangeStatus = (state) =>
  state.mapState.mapStylesChangedStatus;
export const getScreenShootButtonStateST = (state) =>
  state.mapState.screenShootButtonState;
export const getPainterGeoJsonDataST = (state) =>
  state.mapState.painterGeoJsonDataST;
export const getRulerGeoJsonDataST = (state) =>
  state.mapState.rulerGeoJsonDataST;
export const getShowNotificationST = (state) =>
  state.mapState.showNotificationST;

//compass
export const getNewAngle = (state) => state.compass.newAngle;
export const getNewRotate = (state) => state.compass.newRotate;
export const getCompassCoordinates = (state) =>
  state.compass.compassCoordinates;
export const getResetCompass = (state) => state.compass.resetCompass;
export const getDisableCompass = (state) => state.compass.disable;

//timeline

export const getTimeLineZoomST = (state) => state.mapState.timelineZoomST;
export const getTimeLineZoomIndexST = (state) =>
  state.mapState.timelineZoomIndexST;
export const getTimeLineMoveCountST = (state) =>
  state.mapState.timeLineMoveCountST;
export const getTimeLineCursorStatusST = (state) =>
  state.mapState.timeLineCursorStatusST;
export const getFirstTimeLineCursorStatusST = (state) =>
  state.mapState.firstTimeLineCursorStatusST;
export const getTimeLineEventDataST = (state) =>
  state.mapState.timeLineEventDataST;
export const getTimeLineMovePositionST = (state) =>
  state.mapState.timeLineMOvePosition;
export const getTimeLineMoveStatusST = (state) =>
  state.mapState.timeLineMoveStatus;
export const getTimeTineZoomLevelST = (state) =>
  state.mapState.timeTineZoomLevel;

export const getScreenShotLoadingST = (state) =>
  state.mapState.screenShotLoading;
export const getMapCenterCoordinatesST = (state) =>
  state.mapState.mapCenterCoordinates;
export const getMapCenterCoordinatesStatusST = (state) =>
  state.mapState.mapCenterCoordinatesStatus;

export const getScreenShotSendDataST = (state) =>
  state.mapState.screenShotSendData;
export const getScreenShotIntermediateDataST = (state) =>
  state.mapState.screenShotIntermediateData;
export const getScreenShotButtonClickST = (state) =>
  state.mapState.screenShotButtonClick;
export const getIsReadMoreState = (state) =>
  state.mapState.isReadMore;
export const getIsGalleryMode = (state) =>
  state.mapState.isGalleryMode;
export const getGalleryIndex = (state) =>
  state.mapState.galleryIndex;
export const getGalleryTiliIndex = (state) =>
  state.mapState.galleryTiliIndex;
export const getGalleryFullScreen = (state) =>
  state.mapState.galleryFullScreen;
export const getGalleryArticleId = (state) =>
  state.mapState.galleryArticleId;
export const getArticlePosition = (state) =>
  state.mapState.articlePosition;

export const getClosedBlocks = (state) =>
  state.mapState.closedBlocks;
export const getScreenShotLoading = (state) => state.mapState.isLoading;


export const getRemoveCardId = (state) => state.mapState.removeCardId;

export const getAudioState = (state) => state.audio;
export const getAudioPlaying = (state) => getAudioState(state).audioPlaying;

export const getTimeLineMovieDataStatusST = (state) =>
  state.mapState.timeLineMovieDataStatus;

export const getTimeLineMovieDataST = (state) =>
  state.mapState.timeLineMovieData;

export const getTopicStaticTitleST = (state) => state.mapState.topicStaticTitle;
export const getTimeLineClickState = (state) =>
  state.mapState.timeLineClickState;
export const getMobileMenuState = (state) => state.mapState.mobileMenuState;

export const getTimeLineHeightST = (state) =>
    state.mapState.timeLineHeight;



export const getLegends = (state) => state.legends;
export const getLegendsLoading = (state) => getLegends(state).isLoading;
export const getLegendsData = (state) => getLegends(state).data;
export const getFilteredLegendsData = (state) => getLegends(state).filteredData;

