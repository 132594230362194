import { connect } from "react-redux";
import React, {useEffect, useState, useCallback, useRef} from "react";
import { StrongCollaps, TriggerSection } from "../UI/StrongCollaps";
import AudioPlayer from "../audioPlayer";
import {
  GallerySection,
  List,
  RightBarBtnBox,
  RightBarTags,
  RightBarMapIframe,
  DangerouslySetInnerHTML,
} from "../UI/ArticleReadMoreComponents/ArticleReadMoreComponents";
import {
  getArticleReadMoreFontSize,
  getArticleIsLikedData,
  getArticlesData,
  getArticlesDataAll,
  getCategoriesData,
  getFilteredCategoriesData,
  getIsArticlesAllLoading, getArticlePosition, getClosedBlocks,
} from "../../store/selectors";
import {
  ARTICLE_READ_MORE_SECTIONS,
  ARTICLE_GALLERY_FILTER_TYPES,
  ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS,
} from "./constants";
import {
  getGeneralInforimation,
  getCategorySection,
  getAffiliations,
  getTagsInfo,
  getOtherConnectionsInfo,
} from "./utils/utils";
import "./style.css";
import Like from "../UI/CustomCardComponents/Like";
import axios from "axios";
import {likeAricleFromApi, setArticleReadMoreFontSize} from "../../store/actions/articlesAll";
import {getArticleLabels} from "../../service/article";
import ReactQuill from 'react-quill-with-table';
import 'react-quill-with-table/dist/quill.snow.css';
import {ZoomInFont} from "../UI/ZoomInFont";
import useMediaQuery from "@mui/material/useMediaQuery";
import defImg from '../../assets/imgs/image_placeholder.png'
import Loader from "../UI/Loader";
import BasicMap from "../basicMap";
import MapScreenShotButton from "../UI/DynamicScreenshotCard/MapScreenShotButton/MapScreenShotButton";
import {useLocation, useSearchParams} from "react-router-dom";
import {log} from "react-zlib-js";

const ArticleReadMore = (props) => {
  const {
    toggleShowArticlePopup,
    toggleGallery,
    galleryItems,
    articleData,
    articleLoading,
    articleIsLiked,
    likeAricleFromApi,
    categories,
    fontSize,
    setFontSize,
    mapState,
    articlePosition,
    closedSections
  } = props;
  let [searchParams] = useSearchParams();
  const isPresentation = searchParams.get("presentation");
  const isPlayMode = searchParams.get("playMode");
  const preview = searchParams.get("preview");
  const matches = useMediaQuery("(min-width:1024px)");
  const [filterTypes, setFilterTypes] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [labels,setLabels] = useState([])
  const [screenPosition, setScreenPosition] = useState(articlePosition ?? 0)
  const [linkedArticle, setLinkedArticle] = useState({})
  const [overlayPosition, setOverlayPosition] = useState({position: 'absolute', top: 0, left: 0 });
  const [closedBlocks, setClosedBlocks] = useState(closedSections ?? [])
  const screenWidth = window.innerWidth;
  const quillRef = useRef(null);
  const quillRefDesc = useRef(null);
  const linkHoveredRef = useRef({});
  const configureFontSize = (type) => {
    if (type === 'plus' && fontSize < (screenWidth < 1024 ? 24 : 22)) {
      setFontSize(fontSize + 2);
      document.documentElement.style.setProperty('--inner-div-font-size', `${fontSize+2}px`);
    } else if (type === 'minus' && fontSize > (screenWidth < 1024 ? 18 : 16)) {
      setFontSize(fontSize - 2);
      document.documentElement.style.setProperty('--inner-div-font-size', `${fontSize-2}px`);
    }
  };
  const getArticleById = (id) => {
    axios.get(`${process.env.REACT_APP_INDEXING_URL}/api/Article/${id}`)
        .then(res => {
          setLinkedArticle(res.data.data[0])
        })
        .catch(err => console.error(err))
  }

  const removeHtmlTags = (htmlString) => {
    const regex = /(<([^>]+)>)/gi;
    return htmlString.replace(regex, "");
  };

  const extractArticleID = (url) => {
    const match = url.match(/articleID=(\d+)/);
    return match ? match[1] : null;
  };

  const handleLinkHover = (event) => {
    const overlayDiv = document.querySelector('.overlay-div');
    if (overlayDiv) {
      const link = event.currentTarget;
      const linkRect = link.getBoundingClientRect();
      const mouseX = event.clientX;
      const maxLeftPosition = window.innerWidth - overlayDiv.offsetWidth;
      const maxTopPosition = window.innerHeight - overlayDiv.offsetHeight;
      const leftPosition = mouseX - overlayDiv.offsetWidth / 2 - 135;
      const topPosition = linkRect.top - 140 + window.scrollY;
      setOverlayPosition({
        top: `${Math.min(maxTopPosition, topPosition)}px`,
        left: `${Math.min(maxLeftPosition, leftPosition) < 0 ? 5 : Math.min(maxLeftPosition, leftPosition)}px`,
        position: 'absolute',
      });

      const articleID = extractArticleID(link.outerHTML);
      if (articleID && !linkHoveredRef.current[articleID]) {
        getArticleById(articleID);
        linkHoveredRef.current[articleID] = true;
      }
      overlayDiv.style.display = 'block';
    }
  };

  const handleLinkLeave = () => {
    const overlayDiv = document.querySelector('.overlay-div');
    if (overlayDiv) {
      overlayDiv.style.display = 'none';
      setLinkedArticle('');
    }
    linkHoveredRef.current = {};
  };

  useEffect(() => {
    getArticleLabels().then(res => {
      setLabels(res.items)
    })

  },[])

  useEffect(() => {
    const quill = quillRef?.current?.getEditor();
    if (quill) {
      quill.root.querySelectorAll('a').forEach((link) => {
        link.addEventListener('mouseenter', handleLinkHover);
        link.addEventListener('mouseleave', handleLinkLeave);
      });
      return () => {
        quill.root.querySelectorAll('a').forEach((link) => {
          link.removeEventListener('mouseenter', handleLinkHover);
          link.removeEventListener('mouseleave', handleLinkLeave);
        });
      };
    }
  }, [getArticleById, extractArticleID, setLinkedArticle, linkHoveredRef,quillRef.current]);

  useEffect(() => {
    const quill = quillRefDesc?.current?.getEditor()
    if (quill) {
      quill.root.querySelectorAll('a').forEach((link) => {
        link.addEventListener('mouseenter', handleLinkHover);
        link.addEventListener('mouseleave', handleLinkLeave);
      });
      return () => {
        quill.root.querySelectorAll('a').forEach((link) => {
          link.removeEventListener('mouseenter', handleLinkHover);
          link.removeEventListener('mouseleave', handleLinkLeave);
        });
      };
    }
  }, [quillRefDesc, getArticleById, extractArticleID, setLinkedArticle, linkHoveredRef])

  useEffect(() => {
    const handleResize = () => {
      const newScreenWidth = window.innerWidth;
      if (newScreenWidth < 1024 && fontSize === null) {
        setFontSize(18);
      } else if (fontSize === null) {
        setFontSize(16);
      }
      document.documentElement.style.setProperty('--inner-div-font-size', `${fontSize}px`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  const filterGallery = useCallback(
    (val) => {
      setGallery((prev) => [
        ...prev?.map((item) => {
          item.visibility = true;
          if (val.length > 0 && !val.includes(item.type))
            item.visibility = false;
          return item;
        }),
      ]);
    },
    [filterTypes]
  );
  const changeFilterTypes = useCallback(
    (id) => {
      setFilterTypes((prev) => [
        ...prev?.map((el) => {
          el.checked = false;
          if (el.id === id) {
            el.checked = true;
            filterGallery(el.type);
          }
          return el;
        }),
      ]);
    },
    [filterTypes]
  );
  const onChangePlayer = (type, state) => {
    if (!articleData[0].articleData.gallery?.[0]["saveData"]) {
      articleData[0].articleData.gallery[0].saveData = {
        isPlay: null,
        currentTime: null,
        volumeToggle: null,
        volume: null,
      };
    }
    switch (type) {
      case "playing":
        articleData[0].articleData.gallery[0].saveData["playing"] = state;
        break;
      case "currentTime":
        articleData[0].articleData.gallery[0].saveData["currentTime"] = state;
        break;
      case "volumeToggle":
        articleData[0].articleData.gallery[0].saveData["volumeToggle"] = state;
        break;
      case "volume":
        articleData[0].articleData.gallery[0].saveData["volume"] = state;
        break;
      default:
        break;
    }
  };

  // const getInfoSection = (el) =>{
  //
  //     let result = true
  //         if(el.section === 'description') result =  articleData[0].articleData?.content[0]?.introText
  //         if(el.section === 'gallery-section') result = gallery
  //         if(el.section === 'map') result =  {articleId: articleData[0].articleData?.id}
  //         if(el.section === 'main-content') result =  articleData[0].articleData?.content[0]?.formattedText
  //         if(el.section === 'notes') result = articleData[0].articleData?.link
  //         if(el.section === 'general-information') result = ()=>getGeneralInforimation(el,articleData)
  //         if(el.section === 'category') result = () => {
  //             const articlesSubCatsIds = articleData[0].articleData?.subCategory?.reduce((acum,el)=> {
  //                 acum[el.subCategoryId] = {
  //                     id:el.subCategoryId,
  //                     isMain:el.isMain
  //                 }
  //                 return acum
  //             },{})
  //             if(categories.length !== 0){
  //             const filterCat = window.structuredClone(categories)
  //                 ?.filter(el => el?.id === articleData[0].articleData?.category[0]?.categoryId);
  //                 filterCat[0].subCategories = filterCat[0]?.subCategories?.reduce((acum,el) => {
  //                      let resObj = {}
  //                      if(articlesSubCatsIds[el.id]){
  //                          resObj = {
  //                              isMain:articlesSubCatsIds[el.id].isMain,
  //                              ...el
  //                          }
  //                          acum.push(resObj)
  //                      }
  //                      return acum
  //                  },[])
  //                 return getCategorySection(el, filterCat);
  //             }
  //
  //             return  []
  //
  //         }
  //         if(el.section === 'affiliation') result = ()=>getAffiliations(el,articleData)
  //         if(el.section === 'other-connections') result = ()=>getOtherConnectionsInfo(el,articleData)
  //         if(el.section === 'tags') result = ()=>getTagsInfo(el,articleData)
  //         if(el.section === 'like') result = articleData[0].articleData?.like
  //     return result
  // }
  const getInfoSection = (el) => {
    switch (el.section) {
      case "description":
        return articleData[0].articleData?.content[0]?.introText;
      case "gallery-section":
        return gallery;
      case "map":
        return {
          articleId: articleData[0].articleData?.id,
          year:articleData[0].articleData?.startYear || 100 ,
          isBc:articleData[0].articleData?.startTimeIsBC
        };
      case "main-content":
        return articleData[0].articleData?.content[0]?.formattedText;
      case "notes":
        return articleData[0].articleData?.link;
      case "general-information":
        return () => (!articleData[0].articleData?.withoutTimeRange && !articleData[0].articleData?.hidePreviewTimeInfo) && getGeneralInforimation(el, articleData, {label:labels?.find(item => item.id === articleData[0].articleData?.labelId)?.title});
      case "category":
        return () => {
          const articlesSubCatsIds =
            articleData[0].articleData?.subCategory?.reduce((acum, el) => {
              acum[el.subCategoryId] = {
                id: el.subCategoryId,
                isMain: el.isMain,
              };
              return acum;
            }, {});
          if (categories.length !== 0) {
            const filterCat = window
              .structuredClone(categories)
              ?.filter(
                (el) =>
                  el?.id ===
                  articleData[0].articleData?.category[0]?.categoryId
              );
            filterCat[0].subCategories = filterCat[0]?.subCategories?.reduce(
              (acum, el) => {
                let resObj = {};
                if (articlesSubCatsIds[el.id]) {
                  resObj = {
                    isMain: articlesSubCatsIds[el.id].isMain,
                    ...el,
                  };
                  acum.push(resObj);
                }
                return acum;
              },
              []
            );
            return getCategorySection(el, filterCat);
          }

          return [];
        };
      case "affiliation":
        return () => getAffiliations(el, articleData);
      case "other-connections":
        return () => getOtherConnectionsInfo(el, articleData);
      case "tags":
        return () => getTagsInfo(el, articleData);
      case "like":
        return articleData[0].articleData?.like;
      default:
        return true;
    }
  };
  const getInfoSectionCollaps = (el) => {
    let result = getInfoSection(el);
    if (Array.isArray(result)) {
      result = !!getInfoSection(el)?.length;
    } else if (typeof result === "string" || typeof result === "object") {
      result = !!getInfoSection(el);
    } else if (typeof result === "function") {
      result = !!result()?.content?.length;
    }
    return result;
  };
  const handleLikeArticle = useCallback(
    (id) => {
      const body = {
        articleId: id,
      };
      likeAricleFromApi(body, !articleIsLiked);
    },
    [articleIsLiked]
  );
  useEffect(() => {
    setFilterTypes(() => structuredClone([...ARTICLE_GALLERY_FILTER_TYPES]));
  }, []);

  useEffect(() => {
    if (galleryItems?.length > 0) setGallery(() => structuredClone([...galleryItems]) );
  }, [galleryItems]);

  const handleScroll = (event) => {
    const { scrollTop } = event?.target;
    setScreenPosition(scrollTop);
  };

  const onCloseBlock = (index) => {
    if (!closedBlocks.includes(index)) {
      setClosedBlocks([...closedBlocks, index]);
    }
  };

  const onOpenBlock = (index) => {
    if (closedBlocks.includes(index)) {
      setClosedBlocks(closedBlocks.filter((el) => el !== index));
    }
  };

  const setNewScrollPosition = (newScrollPosition) => {
    const articleReadMoreBody = document.querySelector('.article-read-more__body');
     if(articleReadMoreBody) {
       articleReadMoreBody.scrollTo({ top: newScrollPosition, behavior: 'smooth' });
     }
  };

  useEffect(() => {
    setNewScrollPosition(+articlePosition)
  },[articlePosition, document.querySelector('.article-read-more__body')])

  return (
    <>
      {screenWidth >= 1024
          ? <section id={"article-read-more"} className={"article-read-more"}>
            <div className={"article-read-more__header"}>
              <div className={"article-read-more-header__title"}>
                <h2>
                  {articleData[0].articleData?.content[0]?.mainTitle}
                  {localStorage.getItem('admin') !== 'true' && <Like
                      liked={articleIsLiked}
                      handleClick={() =>
                          handleLikeArticle(articleData[0].articleData?.id)
                      }
                  />}
                </h2>
              </div>
              <div className={"article-read-more-header__close"}>
                <div className='screenshot-btn-article'>
                  {isPlayMode !== "true" && preview !== 'true'
                      ?
                        isPresentation ?
                          <MapScreenShotButton
                              type={'presentation-article'}
                              isReadMoreArticle={true}
                              article={true}
                              map={mapState}
                              articlePosition={screenPosition}/> :
                          <MapScreenShotButton
                              isReadMoreArticle={true}
                              article={true}
                              map={mapState}
                              articlePosition={screenPosition}/>
                      : null
                  }
                </div>
                <button
                    className="raed__more__font"
                    type="button"
                    onClick={() => configureFontSize('minus')}
                >
                  <ZoomInFont active={fontSize !== (screenWidth < 1024 ? 18 : 16)}/>
                </button>
                <button
                    className="raed__more__font"
                    type="button"
                    onClick={() => configureFontSize('plus')}
                >
                  <ZoomInFont size='big' active={fontSize !== (screenWidth < 1024 ? 24 : 22)}/>
                </button>
                <span onClick={() => toggleShowArticlePopup()}>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="61px"
                  height="61px"
                  viewBox="0 0 61 61"
                  version="1.1"
              >
                <title>Group 2</title>
                <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeDasharray="0,0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                  <g
                      id="Read-more"
                      transform="translate(-3726.000000, -51.000000)"
                      stroke="#2C476C"
                      strokeWidth="4.9"
                  >
                    <g
                        id="Group-2"
                        transform="translate(3726.000000, 55.000000)"
                    >
                      <line
                          x1="-6.58813919"
                          y1="26.1911362"
                          x2="67.5881392"
                          y2="26.4242484"
                          id="Vector"
                          transform="translate(30.500000, 26.307692) rotate(-315.000000) translate(-30.500000, -26.307692) "
                      />
                      <line
                          x1="30.6165561"
                          y1="63.3958315"
                          x2="30.3834439"
                          y2="-10.7804469"
                          id="Vector"
                          transform="translate(30.500000, 26.307692) rotate(-315.000000) translate(-30.500000, -26.307692) "
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </span>
              </div>
            </div>

            <div className={"article-read-more__body"} onScroll={handleScroll}>
              <div className={"article-read-more-body__main"}>
                {ARTICLE_READ_MORE_SECTIONS?.map((el, index) => (
                    <StrongCollaps
                        key={el.id}
                        onCloseBlock={() => onCloseBlock(el.id)}
                        onOpenBlock={() => onOpenBlock(el.id)}
                        trigger={<TriggerSection title={el.title}/>}
                        contentLength={getInfoSectionCollaps(el)}
                        openState={!closedBlocks.find((block) => block === el.id)}
                    >
                      {el.section === "description" && (
                          <ReactQuill
                              ref={quillRefDesc}
                              value={getInfoSection(el)}
                              readOnly={true}
                              theme={'snow'}
                              modules={{
                                table:true
                              }}
                          />
                      )}
                      {el.section === "gallery-section" && (
                          <GallerySection
                              mapState={mapState}
                              changeFilterTypes={changeFilterTypes}
                              filterGallery={filterGallery}
                              filterTypes={filterTypes}
                              gallery={getInfoSection(el)}
                              toggleGallery={toggleGallery}
                          ></GallerySection>
                      )}
                      {el.section === "map" && (
                          <BasicMap content={getInfoSection(el)}/>
                          // <RightBarMapIframe content={getInfoSection(el)}/>
                      )}
                      {el.section === "main-content" && (
                          <div>
                            <ReactQuill
                                ref={quillRef}
                                value={getInfoSection(el)}
                                readOnly={true}
                                theme={'snow'}
                            />
                          </div>
                      )}
                      {el.section === "notes" && (
                          <List content={getInfoSection(el)}></List>
                      )}
                    </StrongCollaps>
                ))}
              </div>
              {<div className="overlay-div" style={overlayPosition}>
                {linkedArticle?.content ?
                    <div>
                      <p className='linked-article-header'>{linkedArticle?.content?.[0]?.mainTitle}</p>
                      <div>
                        <img src={linkedArticle?.gallery?.[0]?.path ?? defImg}/>
                        <p>
                          {removeHtmlTags(linkedArticle?.content?.[0]?.introText)}
                        </p>
                      </div>
                    </div> : <div className={'overlay-loader'}><Loader/></div>}
              </div>}
              <div className={"article-read-more-body__right-bar"}>
                <div className={"article-read-more-body-right-bar__header-section"}>
                  {articleData[0].articleData?.content[0]?.featuredImage && (
                      <div className={"hero__media_animation__box_square"}>
                        <div className={"hero__media_animation_square"}>
                          <img
                              src={
                                articleData[0].articleData?.content[0]?.featuredImage
                              }
                          />
                        </div>
                        <div className={"hero__media__liner-gradien"}></div>
                      </div>
                  )}
                  {articleData[0].articleData?.gallery?.length &&
                  articleData[0].articleData?.gallery?.findIndex(
                      (x) => x.type === 4 || x.type === "Audio"
                  ) !== -1 ? (
                      <div className={"article-read-more-body-right-bar__audio"}>
                        <AudioPlayer
                            onChangePlayer={onChangePlayer}
                            saveData={articleData?.[0]?.gallery?.[0]?.saveData}
                        />
                      </div>
                  ) : null}
                </div>
                {ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS?.map((el, idx) => (
                    <StrongCollaps
                        key={el?.id}
                        onCloseBlock={() => onCloseBlock(el.id + 10)}
                        onOpenBlock={() => onOpenBlock(el.id + 10)}
                        trigger={<TriggerSection title={el?.title}/>}
                        contentLength={getInfoSectionCollaps(el)}
                        openState={!closedBlocks.find((el) => el === el.id + 10)}
                    >
                      {el.section === "general-information" && (
                          <RightBarBtnBox content={getInfoSection(el)()}/>
                      )}
                      {el.section === "category" && (
                          <RightBarBtnBox content={getInfoSection(el)()}/>
                      )}
                      {el.section === "affiliation" && (
                          <RightBarBtnBox content={getInfoSection(el)()}/>
                      )}
                      {el.section === "other-connections" && (
                          <RightBarBtnBox content={getInfoSection(el)()}/>
                      )}
                      {el.section === "tags" && (
                          <RightBarTags content={getInfoSection(el)()}/>
                      )}
                    </StrongCollaps>
                ))}
              </div>
            </div>
          </section>
          : <section id={"article-read-more-mobile"} className={"article-read-more"}>
            <div className={"article-read-more__header"}>
              <div className={"article-read-more-header__title"}>
                <h2>
                  {articleData[0].articleData?.content[0]?.mainTitle}
                  {localStorage.getItem('admin') !== 'true' && <Like
                      liked={articleIsLiked}
                      handleClick={() =>
                          handleLikeArticle(articleData[0].articleData?.id)
                      }
                  />}
                </h2>
              </div>
              <div className={"article-read-more-header__close"}>
                <button
                    className="raed__more__font"
                    type="button"
                    onClick={() => configureFontSize('minus')}
                >
                  <ZoomInFont active={fontSize !== (screenWidth < 1024 ? 18 : 16)}/>
                </button>
                <button
                    className="raed__more__font"
                    type="button"
                    onClick={() => configureFontSize('plus')}
                >
                  <ZoomInFont size='big' active={fontSize !== (screenWidth < 1024 ? 24 : 20)}/>
                </button>
                <span onClick={() => toggleShowArticlePopup()}>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="61px"
                  height="61px"
                  viewBox="0 0 61 61"
                  version="1.1"
              >
                <title>Group 2</title>
                <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeDasharray="0,0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                  <g
                      id="Read-more"
                      transform="translate(-3726.000000, -51.000000)"
                      stroke="#2C476C"
                      strokeWidth="4.9"
                  >
                    <g
                        id="Group-2"
                        transform="translate(3726.000000, 55.000000)"
                    >
                      <line
                          x1="-6.58813919"
                          y1="26.1911362"
                          x2="67.5881392"
                          y2="26.4242484"
                          id="Vector"
                          transform="translate(30.500000, 26.307692) rotate(-315.000000) translate(-30.500000, -26.307692) "
                      />
                      <line
                          x1="30.6165561"
                          y1="63.3958315"
                          x2="30.3834439"
                          y2="-10.7804469"
                          id="Vector"
                          transform="translate(30.500000, 26.307692) rotate(-315.000000) translate(-30.500000, -26.307692) "
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </span>
              </div>
            </div>

            <div className={"article-read-more__body"}>
              <div className={"article-read-more-body__main"}>
                <StrongCollaps
                    key={1}
                    onCloseBlock={onCloseBlock}
                    onOpenBlock={onOpenBlock}
                    trigger={<TriggerSection title={`ՀԱՄԱՌՈՏ ՏԵՂԵԿՈՒԹՅՈՒՆ`}/>}
                    openState={!closedBlocks.find((el) => el === 0)}
                    contentLength={getInfoSectionCollaps(ARTICLE_READ_MORE_SECTIONS[0])}
                >
                  <DangerouslySetInnerHTML
                      content={getInfoSection(ARTICLE_READ_MORE_SECTIONS[0])}
                  />
                </StrongCollaps>

                <div className={"article-read-more-body__right-bar"}>
                  <div
                      className={"article-read-more-body-right-bar__header-section"}
                      id="article-read-more-body-right-bar__header-section"
                  >
                    {articleData[0].articleData?.content[0]?.featuredImage && (
                        <div className={"hero__media_animation__box_square"}>
                          <div className={"hero__media_animation_square"}>
                            <img
                                src={
                                  articleData[0].articleData?.content[0]?.featuredImage
                                }
                            />
                          </div>
                          <div className={"hero__media__liner-gradien"}></div>
                        </div>
                    )}
                    {articleData[0].articleData?.gallery?.length &&
                    articleData[0].articleData?.gallery?.findIndex(
                        (x) => x.type === 4 || x.type === "Audio"
                    ) !== -1 ? (
                        <div className={"article-read-more-body-right-bar__audio"}>
                          <AudioPlayer
                              onChangePlayer={onChangePlayer}
                              saveData={articleData?.[0]?.gallery?.[0]?.saveData}
                          />
                        </div>
                    ) : null}
                    {ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS?.length > 0 && (
                        <>
                          {ARTICLE_READ_MORE_RIGHT_BAR_SECTIONS?.map((el, idx) => (
                              <StrongCollaps
                                  key={el?.id}
                                  onCloseBlock={() => onCloseBlock(el.id)}
                                  onOpenBlock={() => onOpenBlock(el.id)}
                                  trigger={<TriggerSection title={el?.title}/>}
                                  contentLength={getInfoSectionCollaps(el)}
                                  openState={!closedBlocks.find((el) => el === el.id)}
                              >
                                {el.section === "general-information" && (
                                    <RightBarBtnBox content={getInfoSection(el)()}/>
                                )}
                                {el.section === "category" && (
                                    <RightBarBtnBox content={getInfoSection(el)()}/>
                                )}
                                {el.section === "affiliation" && (
                                    <RightBarBtnBox content={getInfoSection(el)()}/>
                                )}
                                {el.section === "other-connections" && (
                                    <RightBarBtnBox content={getInfoSection(el)()}/>
                                )}
                                {el.section === "tags" && (
                                    <RightBarTags content={getInfoSection(el)()}/>
                                )}
                              </StrongCollaps>
                          ))}
                        </>
                    )}
                  </div>
                </div>

                {ARTICLE_READ_MORE_SECTIONS?.map((el, i) => {
                  return (
                      i > 0 && (
                          <StrongCollaps
                              key={el.id}
                              onCloseBlock={() => onCloseBlock(el.id)}
                              onOpenBlock={() => onOpenBlock(el.id)}
                              trigger={<TriggerSection title={el.title}/>}
                              contentLength={getInfoSectionCollaps(el)}
                              openState={!closedBlocks.find((el) => el === el.id)}
                          >
                            {el.section === "description" && (
                                <ReactQuill
                                    ref={quillRefDesc}
                                    value={getInfoSection(el)}
                                    readOnly={true}
                                    theme={'snow'}
                                    modules={{
                                      table:true
                                    }}
                                />
                            )}
                            {el.section === "gallery-section" && (
                                <GallerySection
                                    changeFilterTypes={changeFilterTypes}
                                    filterGallery={filterGallery}
                                    filterTypes={filterTypes}
                                    gallery={getInfoSection(el)}
                                    toggleGallery={toggleGallery}
                                ></GallerySection>
                            )}
                            {el.section === "map" && (
                                <BasicMap content={getInfoSection(el)}/>
                                // <RightBarMapIframe content={getInfoSection(el)}/>
                            )}
                            {el.section === "main-content" && (
                              <ReactQuill
                                  ref={quillRef}
                                  value={getInfoSection(el)}
                                  readOnly={true}
                                  theme={'snow'}
                              />
                            )}
                            {/*{el.section === "main-content" && (*/}
                            {/*  <DangerouslySetInnerHTML content={getInfoSection(el)} />*/}
                            {/*)}*/}
                            {el.section === "notes" && (
                                <List content={getInfoSection(el)}></List>
                            )}
                          </StrongCollaps>
                      )
                  );
                })}
              </div>
            </div>
          </section>
      }


    </>
  );
};
const mapStateTopProps = (state) => ({
  articleData: getArticlesDataAll(state),
  articleLoading: getIsArticlesAllLoading(state),
  filteredCategories: getFilteredCategoriesData(state),
  articleIsLiked: getArticleIsLikedData(state),
  categories: getCategoriesData(state),
  fontSize: getArticleReadMoreFontSize(state),
  articlePosition: getArticlePosition(state),
  closedSections: getClosedBlocks(state)
});

const mapDispatchToProps = {
  likeAricleFromApi: likeAricleFromApi,
  setFontSize: setArticleReadMoreFontSize,
};
export default connect(mapStateTopProps, mapDispatchToProps)(ArticleReadMore);
